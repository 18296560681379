<template>
	<div id="appList">
		<div class="app-row" v-if="list">
			<div class="app-row-item" v-for="(item, index) in list" :key="index">
				<div class="ari-useing" v-if="item.is_use == 1">使用中</div>
				<div class="ari-useing ari-jinyong" v-else>已过期</div>
				<div class="ari-version">
					{{ item.server.server_name }}
					<span v-if="item.is_trail == 1">(试用版)</span>
				</div>
				<img class="ari-img" v-if="item.logo" :src="item.logo" alt="" />
				<img class="ari-img" v-else src="../../assets/img/cover.jpg" alt="" />
				<div class="ari-right">
					<p>{{ item.program_name }}</p>
					<p>APPID:{{ item.program_id }}</p>
					<p v-if="item.user">用户：{{ item.user.name }}</p>
					<p v-if="item.end_days > 0">剩余天数：{{ item.end_days }}</p>
					<p v-else style="color: red;">已过期</p>
				</div>
				<div class="ari-btn">
					<div v-if="item.is_use == 1 || $store.getters.userInfo.rank == 1" @click="toMiniProgram(item.program_id)">
						<p>管</p>
						<p>理</p>
					</div>
				</div>
				<a-dropdown class="menu-down">
					<a class="ant-dropdown-link" @click.prevent><i class="ri-arrow-down-s-line"></i></a>
					<template #overlay>
						<a-menu>
							<span v-has="{ action: '/admin/app/app_edit' }" @click="handleCommand('edit_' + item.program_id)">
								<a-menu-item>编辑 </a-menu-item>
							</span>
							<span v-has="{ action: 'sass_app_chenge_server' }" @click="handleCommand('server_' + item.program_id)">
								<a-menu-item >切换分支</a-menu-item>
							</span>
							<div v-has="{ action: 'sass_app_remove' }" >
								<div @click="handleCommand('remove_' + item.program_id)">
									<a-menu-item >迁移</a-menu-item>
								</div>
							</div>
							<span v-has="{ action: 'sass_app_change_time' }" @click="handleCommand('changeday_' + item.program_id)">
								<a-menu-item>变更天数</a-menu-item>
							</span>
							<span v-has="{ action: 'sass_app_back' }">
								<div @click="handleCommand('back_' + item.program_id)">
									<a-menu-item >恢复</a-menu-item>
								</div>
							</span>
							<div v-has="{ action: 'sass_app_del' }">
								<div @click="handleCommand('delete_' + item.program_id)">
									<a-menu-item >删除</a-menu-item>
								</div>
							</div>
							<div v-has="{ action: 'sass_app_set_plugin' }">
								<div @click="showSetPlugin(item)">
									<a-menu-item >插件设置</a-menu-item>
								</div>
							</div>
							<div v-has="{ action: 'sass_app_plugin_order' }">
								<div @click="handleCommand('pluginOrder_' + item.program_id)">
									<a-menu-item >插件订单</a-menu-item>
								</div>
							</div>
							<span v-has="{ action: 'sass_app_change_shortmsg_num' }" @click="handleCommand('shortmsg_' + item.program_id)">
								<a-menu-item>短信天数配置</a-menu-item>
							</span>
						</a-menu>
					</template>
				</a-dropdown>
			</div>
			<div class="none-app" v-if="list.length == 0">
				<div>您还没有创建应用哦</div>
				<router-link to="/admin/app/app_edit">
					<a-button type="primary">立即创建</a-button>
				</router-link>
			</div>
		</div>

		<!-- 切换分支 -->
		<a-modal :visible="show.branch" title="切换分支" cancel-text="取消" ok-text="确定" @ok="sureUpdateServer" @cancel=" () => { show.branch = false; } " class="change-server" >
			<com-server-select @success="e =>updateService.server_id = e"></com-server-select>
		</a-modal>

		<a-modal :visible="show.remove" title="迁移应用" cancel-text="取消" ok-text="确定" @ok="sureRemove" @cancel=" () => { show.remove = false; } " class="remove-app" :width="840" >
			<div class="remove-app-seach">
				<a-input-search v-model="removeForm.keyword" style="width: 400px" placeholder="请输入手机号或姓名进行查询" enter-button @search="getUserList(1)" />
			</div>
			<div class="remove-app-user">
				<div
					class="remove-app-user-lo"
					@click="removeForm.user_id = item.user_id"
					:class="[item.user_id == removeForm.user_id ? 'li-active-border' : '']"
					v-for="(item, index) in removeForm.list"
					:key="index"
				>
					<img class="lo-pic" v-if="item.pic_url && item.pic_url != null" :src="pic_url" alt="" />
					<img class="lo-pic" v-else src="@/assets/img/moren.png" alt="" />
					<div class="remove-app-user-lo-info">
						<div>{{ item.name }}</div>
						<div>{{ item.mobile }}</div>
					</div>
					<img v-if="item.user_id == removeForm.user_id" class="lo-active" src="../../assets/img/icon-share-selected.png" alt="" />
				</div>
				<div class="remove-app-user-lo" style="height: 1px;border: none;"></div>
				<div class="remove-app-user-lo" style="height: 1px;border: none;"></div>
				<div class="remove-app-user-lo" style="height: 1px;border: none;"></div>
			</div>

			<a-pagination :current="removeForm.page" :total="removeForm.count" />
		</a-modal>

		<a-modal :visible="show.updateTime" title="变更天数" cancel-text="取消" ok-text="确定" @ok="saveUpdateTime" @cancel=" () => { show.updateTime = false; } " class="app-change-time" :width="500" >
			<a-form :model="changeTimeForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
				<a-form-item label="增加/减少">
					<a-radio-group v-model:value="changeTimeForm.type">
						<a-radio :value="1">增加</a-radio>
						<a-radio :value="2">减少</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="天数"><a-input v-model:value="changeTimeForm.day" /></a-form-item>
			</a-form>
		</a-modal>

		<a-modal :visible="show.shortmsg" title="修改短信条数" cancel-text="取消" ok-text="确定" @ok="saveShoetMsg" @cancel=" () => { show.shortmsg = false; } " class="app-change-time" :width="500" >
			<a-form :model="changeTimeForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
				<a-form-item label="增加/减少">
					<a-radio-group v-model:value="shortmsg.op">
						<a-radio value="add">增加</a-radio>
						<a-radio value="sub">减少</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="操作条数"><a-input v-model:value="shortmsg.num" /></a-form-item>
			</a-form>
		</a-modal>

		<a-modal :visible="show.setPlugin" title="设置插件" cancel-text="取消" ok-text="确定" @ok="sureSetPlugin" @cancel=" () => { show.setPlugin = false; } " class="app-set-plugin" :width="1020" >
			<div class="asp-contanier">
				<!-- <template > -->
					<div
						v-for="(item, ind) in pluginForm.list"
						class="aspc-item" 
						:key="ind" 
						@click="item.is_use == 1 ? (item.is_use = 0) : (item.is_use = 1)" 
						>
						<div class="aspc-version" v-if="pluginForm.server && item.is_ser == 1">{{pluginForm.server}}</div>
						<img v-if="item.is_use == 1" class="aspc-icon-gou" src="../../assets/img/icon-share-selected.png"/>
						<div class="aspc-item-info">
							<img class="logo" :src="item.detail.plugin_logo" alt="" />
							<div class="aspc-name">{{ item.detail.plugin_name }}</div>
						</div>
					</div>
				<!-- </template> -->
				<div class="aspc-item" v-for="i in 3" :key="i" style="height: 5px;border: none;"></div>
			</div>
		</a-modal>

		<a-modal :visible="show.pluginOrder" title="插件订单" cancel-text="取消" ok-text="确定" @ok="sureSetPlugin" @cancel=" () => { show.pluginOrder = false; } " class="app-set-plugin" :width="1020" >
			<a-table
				:columns="[
					{ title: 'ID', key: 'id', dataIndex: 'id' },
					{ title: '订单编号', key: 'order_sn', dataIndex: 'order_sn' },
					{ title: '订单金额', key: 'amount_total', dataIndex: 'amount_total' },
					{ title: '支付方式', key: 'pay_type', dataIndex: 'pay_type', scopedSlots: { customRender: 'pay_type' } },
					{ title: '订单状态', key: 'status', dataIndex: 'status', scopedSlots: { customRender: 'status' } },
					{ title: '订单创建时间', key: 'create_time', dataIndex: 'create_time' }
				]"
				:data-source="pluginOrder.list"
				size="small"
				rowKey="id"
				:pagination="false"
			>
				<template #pay_type="{ pay_type }">
					<a-tag :color="pay_type == 'wx' ? 'green' : 'blue'">{{ pay_type == 'wx' ? '微信支付' : '支付宝支付' }}</a-tag>
				</template>
				<template #status="{ status }">
					<a-tag :color="status == 1 ? '#87d068' : '#f50'">{{ status == 1 ? '已支付' : '未支付' }}</a-tag>
				</template>
			</a-table>
			<a-pagination
				:current="pluginOrder.page"
				:total="pluginOrder.count"
				@change=" e => { getPluginOrderList(e, pluginOrder.limit); } "
			/>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import appModel from '@/api/saas/app.js'
import authModel from '@/api/saas/auth.js'
import router from '@/router'
import comServerSelect from '@/components/admin/com-server-select.vue'
export default {
	name: 'app-list',
	components:{
		comServerSelect
	},
	props: {
		list: {
			type: [Array, Object]
		},
		pageCount: {
			type: Number
		},
		type: {
			type: String
		}
	},
	setup(props,context){
		const _d = reactive({
			userDialog: false,
			serverDialog: false,
			removeForm: {
				keyword: '',
				page: 1,
				count: 0,
				limit: 12,
				list: [],
				user_id: 0,
				program_id: 0
			},
			server: [], //分支信息
			updateService: {
				//要修改的分支信息
				server_id: 0,
				program_id: 0
			},
			show: {
				updateTime: false,
				setPlugin: false,
				pluginOrder: false,
				shortmsg: false,
				branch: false,
				remove: false
			},
			changeTimeForm: {
				type: 1,
				day: '',
				program_id: 0
			},
			pluginForm: {
				program_id: 0,
				list: [], //所有插件列表
				buy: [], //已购买插件
				version:"",
			},
			pluginOrder: {
				program_id: 0,
				list: [],
				page: 1,
				count: 0,
				limit: 10
			},
			shortmsg: {
				program_id: 0,
				op: 'add',
				num: ''
			}
		})

		function getUserList(page,limit) {
			authModel.getMerchantUserList(page,limit,{keyword:_d.removeForm.keyword},res=>{
				_d.removeForm.list = res.list
				_d.removeForm.page = res.page
				_d.removeForm.count = res.count
			})
		}

		function handleCommand(command){
			let opration = command.split('_');
			if (opration[0] == 'edit') {
				router.push({path:'/admin/app/app_edit',query:{program_id:opration[1]}})
				return;
			}

			if (opration[0] == 'delete') {
				deleteWxapp(opration[1], 1);
				return
			}
			if (opration[0] == 'back') {
				deleteWxapp(opration[1], 2);
				return
			}

			if (opration[0] == 'server') {
				//更换分支操作
				_d.show.branch = true;
				_d.updateService.program_id = opration[1];
				return
			}

			if (opration[0] == 'remove') {
				_d.show.remove = true;
				_d.removeForm.program_id = opration[1];
				getUserList(1);
				return
			}

			//改变天数
			if (opration[0] == 'changeday') {
				_d.show.updateTime = true;
				_d.changeTimeForm.program_id = opration[1];
			}

			//设置插件
			if (opration[0] == 'plugin') {
				_d.pluginForm.program_id = opration[1];
				_d.show.setPlugin = true;
				appModel.getProgramPlugin(opration[1],res=>_d.pluginForm.list = res)
			}
			//插件订单
			if (opration[0] == 'pluginOrder') {
				_d.pluginOrder.program_id = opration[1];
				_d.show.pluginOrder = true;
				getPluginOrderList(1,_d.pluginOrder.limit)
				return
			}

			//增加短信条数配置
			if (opration[0] == 'shortmsg') {
				_d.show.shortmsg = true;
				_d.shortmsg.program_id = opration[1];
			}
		}

		function showSetPlugin(row){
			_d.pluginForm.program_id = row.program_id
			_d.pluginForm.server = row.server ? row.server.server_name:"";
			_d.show.setPlugin = true;
			appModel.getProgramPlugin(row.program_id,res=>_d.pluginForm.list = res)
		}

		function getPluginOrderList(page, limit) {
			let {program_id} = _d.pluginOrder
			appModel.getPluginOrder(page,limit,{program_id},res=>_d.pluginOrder = {limit,program_id,...res})
		}

		function deleteWxapp(id,type) {
			appModel.changeProgramStatus(id,type,()=>context.emit("changeList",false))
		}

		const toMiniProgram = (id)=>appModel.intoProgram(id)

		const sureRemove = ()=>appModel.migrateProgram(_d.removeForm.program_id,_d.removeForm.user_id,()=>{
			_d.show.remove = false
			_d.removeForm.user_id = 0
		})
		const sureUpdateServer = ()=>appModel.setProgramServer(_d.updateService.program_id,_d.updateService.server_id,()=>{
			_d.serverDialog = false;
			_d.updateService.server_id = 0;
			_d.updateService.program_id = 0;
			let timer = setTimeout(function() {
				location.reload();
				clearTimeout(timer);
			}, 1000);
		})

		const saveUpdateTime = ()=>appModel.setProgramTime(_d.changeTimeForm,()=>{
			_d.show.updateTime = false;
			_d.changeTimeForm.type = 0;
			_d.changeTimeForm.day = '';
			_d.changeTimeForm.program_id = 0;
			context.emit("changeList",false)
		})

		const sureSetPlugin = ()=>appModel.setProgramPlugin(_d.pluginForm,()=>{
			_d.show.setPlugin = false;
			_d.pluginForm.plugin_id = [];
		})

		const saveShoetMsg = ()=>appModel.setProgramShoetMsg(_d.shortmsg,()=>{
			_d.show.shortmsg = false;
			_d.shortmsg = {
				program_id: 0,
				op: 'add',
				num: ''
			}
		})

		return{
			...toRefs(_d),
			deleteWxapp,
			toMiniProgram,
			sureRemove,
			getUserList,
			sureUpdateServer,
			saveUpdateTime,
			sureSetPlugin,
			getPluginOrderList,
			saveShoetMsg,
			handleCommand,
			showSetPlugin
		}
	}
}
</script>

<style lang="scss">
.remove-app {
	&-seach {
		width: 100%;
		height: 50px;

		&-input {
			width: 400px;
		}
	}
	&-user {
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		&-lo {
			width: 210px;
			height: 60px;
			display: flex;
			border: 1px solid #f1f1f1;
			padding: 10px 0 10px 10px;
			margin-bottom: 10px;
			cursor: pointer;
			margin-right: 10px;

			.lo-pic {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				margin-right: 10px;
			}

			&-info {
				width: 80%;
				height: 100%;
			}
		}

		.li-active-border {
			border: 1px solid red;
		}
		.lo-active {
			position: absolute;
			margin-left: 165px;
			margin-top: 15px;
		}
	}
}

.app-row {
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
	// justify-content: space-between;

	.none-app {
		width: 100%;
		color: #868686;
		font-size: 14px;
		text-align: center;
		line-height: 40px;
	}

	&-item {
		width: 290px;
		height: 150px;
		display: flex;
		margin: 0 20px 25px 0;
		align-items: center;
		box-shadow: 0 0 10px #e2e2e2;
		border-radius: 5px;
		cursor: pointer;
		position: relative;

		.ari-useing {
			position: absolute;
			width: 55px;
			height: 20px;
			background: #f79139;
			color: #fff;
			// margin-top: -65px;
			font-size: 12px;
			padding-left: 5px;
			line-height: 20px;
			border-radius: 5px 0 20px 0;
			z-index: 99;
			top: 0;
		}
		.ari-jinyong {
			background: #000000;
		}

		.ari-version {
			position: absolute;
			width: auto;
			height: 20px;
			background: #409eff;
			color: #fff;
			// margin-top: -65px;
			font-size: 12px;
			padding-left: 65px;
			padding-right: 20px;
			line-height: 20px;
			border-radius: 5px 0 20px 0;
			top: 0;
		}

		.ari-img {
			width: 90px;
			height: 90px;
			border-radius: 5px;
			margin: 0 20px;
			margin-top: 20px;
		}

		.ari-right {
			width: 130px;
			line-height: 20px;
			margin-top: 20px;
			p {
				margin: 0;
				color: #999;
				font-size: 12px;
			}
			p:first-child {
				font-size: 15px;
				font-weight: bold;
				color: #000000;
			}
		}

		.ari-btn {
			width: 40px;
			height: 150px;
			background: #409eff;
			color: #fff;
			position: absolute;
			margin-left: 260px;
			border-radius: 0 5px 5px 0;
			text-align: center;
			display: table;
			div {
				display: table-cell;
				vertical-align: middle;

				p {
					margin: 0;
				}
			}
		}

		.menu-down {
			position: absolute !important;
			margin-left: 220px;
			font-size: 20px;
			margin-top: -55px;
		}
	}
}
.app-search-input {
	width: 350px !important;
}

// 更换分支
.change-server {
	.el-dialog__body {
		height: 300px;
		padding: 20px;
	}
	.applist-cs-con {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		.acc-placeholder {
			width: 172px;
			height: 1px;
		}
		&-item {
			width: 170px;
			height: 60px;
			border: 1px solid #999;
			text-align: center;
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: 20px;

			.acci-title {
				color: #e66b02;
				font-size: 18px;
				font-weight: bold;
			}
			.acci-price {
				span {
					color: #999;
					font-size: 12px;
					text-decoration: line-through;
				}
			}
		}
		&-item:hover,
		.acc-active {
			border: 1px solid #00aaff;
			cursor: pointer;
		}
	}

	&-footer {
		width: 100%;
		text-align: center;
	}
}

//变更应用天数
.app-change-time {
	.el-dialog {
		height: 300px;
	}
}

//设置插件
.app-set-plugin {
	.asp-contanier {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;

		.aspc-item {
			width: 220px;
			height: 80px;
			border: 1px solid #cbddef;
			margin: 0 20px 20px 0;
			cursor: pointer;
			border-radius: 5px;
			position: relative;
			&-info{
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
			}
			.logo {
				width: 60px;
				height: 60px;
				margin: 0 10px;
			}

			.aspc-version{
				position: absolute;
				background: #3ada76;
				right: 0;
				top: 0;
				color: #fff;
				padding: 0 4px;
				font-size: 12px;
				border-radius: 0 4px 0 8px
			}
		}
		.aspc-item:hover {
			border: 1px solid #66b1ff;
		}

		.aspc-icon-gou {
			position: absolute;
			margin-left: 195px;
			margin-top: 54px;
			width: 24px;
			height: 24px;
		}
	}

	.asp-footer {
		width: 100%;
		text-align: right;
	}
}
</style>
